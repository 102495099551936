
import './App.css';
import {useEffect, useState} from 'react';
import PlayerCard from './PlayerCard';
import Bg from './Bg';

function App() {
  const defaultplayers = [];
  for (let i = 0; i < 4; i++) {
    defaultplayers.push({
      name: 'abcd'[i],
      score: 0
    });
  }

  const [players, setPlayers] = useState(defaultplayers);
  const [selectedPlayer, selectPlayer] = useState(-1);
  const [PLACES, setPlaces] = useState([1,2,3,4]);
  const [targetScore, setTargetScore] = useState(10000);
  
  const AddScore = amt => {
    if (selectedPlayer === -1) return;
    setPlayers(prev => {
      const newli = [];
      for (let i = 0; i < prev.length; i++) {
        newli.push({...prev[i]});
      }

      newli[selectedPlayer].score += amt;

      return newli;
    });
  }
  
  const AddPlayer = () => {
    const newps = [];
    for (let i = 0; i < players.length; i++) {
      newps.push({
        name: players[i].name,
        score: players[i].score
      })
    }
    newps.push({name: "abcdefghijklmnopqrstuvwxyz"[newps.length], score: 0});
    setPlayers(newps);
  }

  const RemovePlayer = () => {
    const newppl = [];
    for (let i = 0; i < players.length-1; i++) {
      newppl.push({name: players[i].name, score: players[i].score});
    }
    setPlayers(newppl)
  }

  useEffect(() => {
    const scores = players.map(v => v.score);
    const places = new Array(scores.length);

    for (let i = 0; i < scores.length; i++) {
      const cur = scores[i];
      let pos = 1;
      
      for (let j = 0; j < scores.length; j++) {
        if (j === i) continue;
        if (scores[j] > cur) pos++;
      }

      places[i] = pos;
    }

    setPlaces(places);
  }, [players]);


  return (
    <div>
      <Bg />
      <nav className="navbar">
        <div className="navbar-start gap-4 items-center">
          <button onClick={() => {
              setPlayers(prev => {
                for (let i = 0; i < prev.length; i++) {
                  prev[i].score = 0;
                }
                return [...prev];
              });
            }} className="btn btn-outline">Reset</button>
        </div>
        <div className="navbar-end gap-4 items-center">
          <button onClick={RemovePlayer} className="btn btn-sm btn-outline">-</button>
          <button onClick={AddPlayer} className="btn btn-sm btn-outline">+</button>
          <button onClick={() => {

            setTargetScore(prev => {
              if (prev === 10000) {
                return 5000;
              }

              return 10000;
            })

          }} className={"btn btn-sm btn-outline" + (targetScore === 5000 ? ' border-yellow-400 bg-yellow-400 text-black' : '')}>5K</button>
        </div>
      </nav>
      <div className="mx-auto max-w-screen-xl p-4">
        <h1 className="text-5xl font-extrabold text-center">Dice score keeper</h1>
        <div className="flex gap-4 flex-grow flex-wrap">
            {players.map((player, i) => (
             
                <div key={`pl_${i}`} className={`flex-1 rounded-md border-2 ${(PLACES[i] === 1 ? 'shadow-yellow-700 shadow-lg ' : ' ') + (selectedPlayer === i ? 'border-success' : 'border-neutral')}`}>
                  <PlayerCard targetScore={targetScore} player={player} place={PLACES[i]} onChangeName={(name) => {
                    setPlayers(prev => {
                      prev[i].name = name;
                      return [...prev];
                    })
                  }} onSelect={() => {
                    selectPlayer(i);
                  }} onChangeScore={(e) => {
                    setPlayers(prev => {
                      prev[selectedPlayer].score = parseInt(e.target.innerText);
                      return [...prev];
                    })
                  }} />
                </div>
            ))}
        </div>
        <div className="my-4 grid grid-cols-4 gap-4">
          <button onClick={() => AddScore(50)} className="btn">+50</button>
          <button onClick={() => AddScore(100)} className="btn">+100</button>
          <button onClick={() => AddScore(200)} className="btn">+200</button>
          <button onClick={() => AddScore(300)} className="btn">+300</button>
          <button onClick={() => AddScore(400)} className="btn">+400</button>
          <button onClick={() => AddScore(500)} className="btn">+500</button>
          <button onClick={() => AddScore(1000)} className="btn">+1000</button>
          <button onClick={() => AddScore(-50)} className="btn">-50</button>
        </div>
        <div className="my-4">
          <button onClick={() => {
            const a = selectedPlayer + 1;
            selectPlayer(a%players.length)
          }} className="btn btn-info w-full my-4">Next</button>

          

        </div>
      </div>
    </div>
  );
}

export default App;
