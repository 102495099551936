export default function PlayerCard({player, onSelect, place, onChangeName, onChangeScore, targetScore}) {
    function placeSuffix(n) {
        try {
            const sn = n.toString();

            if (sn[sn.length-1] === '1') {
                return sn[sn.length-2] === '1' ? 'th' : 'st';
            }
            if (sn[sn.length-1] === '2') {
                if (sn[sn.length-2] === '1') return 'th';
                else return 'nd';
            }
            if (sn[sn.length-1] === '3') {
                return sn[sn.length-2] === '1' ? 'th' : 'rd'
            }

            return 'th';
        } catch (error) {
            return '';
        }
    }
    return (
        <div onClick={onSelect} className="text-white p-2 md:p-4 md:grid" style={{gridTemplateColumns: '1fr auto'}}>
           <div>
            <div>
                <input type="text" value={player.name} onChange={e => onChangeName(e.target.value)} className="input text-center md:text-left w-full input-sm md:input-lg bg-transparent" />
            </div>
            <p className="md:text-5xl text-center md:text-left">{player.score.toLocaleString()}</p>
           </div>
           <div className="md:text-3xl text-center md:text-right">
            <p className={place === 1 ? 'text-yellow-500 text-5xl' : 'text-base-content'}>{place}{placeSuffix(place)}</p>
            <p className="text-sm opacity-50 md:text-xl">{(targetScore-player.score).toLocaleString()}</p>
           </div>
        </div>
    );
}