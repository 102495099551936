import { useRef } from "react";

export default function Bg() {

    const ref = useRef();

    return (
        <div className="fixed pointer-events-none top-0 left-0 w-full h-screen">
            <canvas ref={ref} className="block w-full h-full"></canvas>
        </div>
    );

}